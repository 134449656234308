<template>
	<div class="site-body">
		<section class="faq-section">
			<div class="container container-max-lg">
				<h1>FAQ</h1>
				<div class="faq-list" id="faqWrap">
					<div class="card">
						<div class="card-header">
							<h5 class="mb-0">
								<button type="button" data-toggle="collapse" data-target="#faq1" aria-expanded="true" aria-controls="faq1">
									Q1. What is BTCTH?
								</button>
							</h5>
						</div>

						<div id="faq1" class="collapse show" data-parent="#faqWrap">
							<div class="card-body">
								<p>A1. BTCTH is a token in which each 1 BTCTH guarantees a bitcoin mining hashrate of 0.01 TH/s.</p>
								<p>BTCTH holders can start or stop bitcoin mining by staking/unstaking BTCTH on the dApp.</p>
							</div>
						</div>
					</div>
					<div class="card">
						<div class="card-header">
							<h5 class="mb-0">
								<button type="button" data-toggle="collapse" data-target="#faq2" aria-expanded="false" aria-controls="faq2">
Q2. How does bitcoin mining work?
								</button>
							</h5>
						</div>

						<div id="faq2" class="collapse" data-parent="#faqWrap">
							<div class="card-body">
								<p>A2. Bitcoin is mined from tokenized hash power.
								The tokenized hash power is supplied by the mining center contracted with the project.</p>
							</div>
						</div>
					</div>
					<div class="card">
						<div class="card-header">
							<h5 class="mb-0">
								<button type="button" data-toggle="collapse" data-target="#faq3" aria-expanded="false" aria-controls="faq3">
Q3. How are mining rewards distributed?
								</button>
							</h5>
						</div>

						<div id="faq3" class="collapse" data-parent="#faqWrap">
							<div class="card-body">
								<p>A3. The mined bitcoins are delivered to the dApp and allocated in proportion to the staking BTCTH.</p>
							</div>
						</div>
					</div>
					<div class="card">
						<div class="card-header">
							<h5 class="mb-0">
								<button type="button" data-toggle="collapse" data-target="#faq4" aria-expanded="false" aria-controls="faq4">
Q4. When can I buy BTCTH?
								</button>
							</h5>
						</div>

						<div id="faq4" class="collapse" data-parent="#faqWrap">
							<div class="card-body">
								<p>A4. BTCTH will be sold through presale and public sale.
									The schedule and method of presale and public sale will be announced
									through official SNS channels.</p>
								<p> It will be listed on the exchange after the end of the public sale,
									and you can buy BTCTH on the exchange at any time after listing.</p>
							</div>
						</div>
					</div>
					<div class="card">
						<div class="card-header">
							<h5 class="mb-0">
								<button type="button" data-toggle="collapse" data-target="#faq5" aria-expanded="false" aria-controls="faq5">
Q5. Are there any conditions to participate in the pre/public sale?
								</button>
							</h5>
						</div>

						<div id="faq5" class="collapse" data-parent="#faqWrap">
							<div class="card-body">
								<p>A5. Excluded jurisdictions include the 
									United States, Canada, Australia, China, Singapore
									and jurisdictions that do not permit the purchase of cryptocurrencies under national law.</p>
							</div>
						</div>
					</div>
					<div class="card">
						<div class="card-header">
							<h5 class="mb-0">
								<button type="button" data-toggle="collapse" data-target="#faq6" aria-expanded="false" aria-controls="faq6">
Q6. How many BTCTH tokens are allocated to the project?
								</button>
							</h5>
						</div>

						<div id="faq6" class="collapse" data-parent="#faqWrap">
							<div class="card-body">
								<p>A6. Unlike existing blockchain projects,
									BTCTH does not have any tokens that are paid out for free to marketing, foundations, and teams.</p>
								<p>Since BTCTH is a token backed by spot assets, there are no free tokens.</p>
							</div>
						</div>
					</div>
					<div class="card">
						<div class="card-header">
							<h5 class="mb-0">
								<button type="button" data-toggle="collapse" data-target="#faq7" aria-expanded="false" aria-controls="faq7">
Q7. What is the total supply of BTCTH?
								</button>
							</h5>
						</div>

						<div id="faq7" class="collapse" data-parent="#faqWrap">
							<div class="card-body">
								<p>A7. 15,000,000 BTCTH.</p>
							</div>
						</div>
					</div>
					<div class="card">
						<div class="card-header">
							<h5 class="mb-0">
								<button type="button" data-toggle="collapse" data-target="#faq8" aria-expanded="false" aria-controls="faq8">
Q8. What features does the dApp support?
								</button>
							</h5>
						</div>

						<div id="faq8" class="collapse" data-parent="#faqWrap">
							<div class="card-body">
								<p>A8. Supports staking and unstaking of BTCTH and claim function of mined bitcoin.</p>
							</div>
						</div>
					</div>
				</div>
				<!-- <ul class="pagination">
					<li class="page-item">
						<a class="page-link" href="#" aria-label="Previous">
							<img src="img/ic-comp-paging-rr.svg">
						</a>
					</li>
					<li class="page-item">
						<a class="page-link" href="#" aria-label="Previous">
							<img src="img/icon-comp-paging-prev.svg">
						</a>
					</li>
					<li class="page-item"><a class="page-link" href="#">1</a></li>
					<li class="page-item"><a class="page-link" href="#">2</a></li>
					<li class="page-item active"><a class="page-link" href="#">3</a></li>
					<li class="page-item"><a class="page-link" href="#">4</a></li>
					<li class="page-item"><a class="page-link" href="#">5</a></li>
					<li class="page-item">
						<a class="page-link" href="#" aria-label="Next">
							<img src="img/icon-comp-paging-next.svg">
						</a>
					</li>
					<li class="page-item">
						<a class="page-link" href="#" aria-label="Next">
							<img src="img/icon-comp-paging-ff.svg">
						</a>
					</li>
				</ul> -->
			</div>
		</section>
	</div>
</template>

<script>
export default {
  name: "Faq",
  props: {},
  watch: {
    $route(to, from) {
    },
  },
  mounted() {
  },
  methods: {
  },
};


</script>